import React, { useState } from "react";

import { decode } from "base64-arraybuffer";
import { uploadImageToBucket } from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";

const ImageUpload = ({ handleImageUpload }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    setSelectedImage(file);
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      alert("Please select an image to upload.");
      return;
    }
    setLoading(true);
    const reader = new FileReader();

    reader.onload = async (event) => {
      const base64FileData = event.target.result.split(",")[1];
      const arrayBuffer = decode(base64FileData);

      const result = await uploadImageToBucket(selectedImage, arrayBuffer);
      setLoading(false);
      if (result.error) {
        toast.error(result.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.log("public url", result);
        if (handleImageUpload) handleImageUpload(result);
      }
    };

    reader.readAsDataURL(selectedImage);
  };

  return (
    <>
      <div class="flex items-center justify-center w-full">
        <label
          for="dropzone-file"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              class="w-8 h-8 mb-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p class="mb-2 text-sm text-gray-500">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs text-gray-500">SVG, PNG, JPG, JPEG</p>
          </div>
          <input
            id="dropzone-file"
            class="hidden"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            onDrop={handleDrop}
          />
        </label>
      </div>
      {selectedImage && (
        <p className="text-green-600 text-xs">
          {selectedImage.name} uploaded successfully! Click on Upload button to
          update it
        </p>
      )}
      <button
        class="w-40 bg-black hover:bg-gray-800 text-white font-bold mt-2 py-2 px-4 border border-gray-700 rounded"
        onClick={handleUpload}
      >
        {loading ? "Uploading..." : "Upload Image"}
      </button>
    </>
  );
};

export default ImageUpload;
