import React, { useEffect, useState } from "react";
import {
  getFanProfileById,
  subscribeUserToMultipleArtists,
  syncAndUpdateAppleArtistData,
  syncAndUpdateSpotifyArtistData,
  updateProfileData,
} from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import Account from "../rtsvrf-assets/icons/account.png";
import WalletIcon from "../rtsvrf-assets/icons/walletconnect.svg";
import { magic } from "../rtsvrf-utils/Magic";

import { SYNC_SPOTIFY, modalCustomStyles } from "../rtsvrf-utils/Constants";
import { logSuccess } from "../rtsvrf-actions/Logging";

Modal.setAppElement("#root");

function SyncSpotify({
  userId,
  userIpData,
  reloadData,
  connecting,
  enableSpotifySyncPrompt,
  setEnableSpotifySyncPrompt,
  setSearchParams,
  isAppleSynced,
  isSpotifySynced,
  appleSyncedArtists,
  spotifySyncedArtists,
  setIsAppleSynced,
  setIsSpotifySynced,
  handleSpotifyConnection,
  handleAuthorizeApple,
}) {
  const [loading, setLoading] = useState();
  const [selectedArtists, setSelectedArtists] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (enableSpotifySyncPrompt) {
      openModal();
      setEnableSpotifySyncPrompt(false);
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableSpotifySyncPrompt]);

  const handleSyncSpotify = async () => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Sync Spotify",
      action: "click",
      label: "Sync Spotify",
    });
    handleSpotifyConnection(SYNC_SPOTIFY);
  };

  const handleSelectedArtists = async (artist) => {
    const list = selectedArtists ? [...selectedArtists] : [];
    if (list.find((e) => e.id === artist.id)) {
      list.splice(list.indexOf(artist), 1);
    } else {
      list.push(artist);
    }
    setSelectedArtists(list);
  };

  const followArtistsOnSpotify = async () => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Sync Spotify",
      action: "click",
      label: "Sync Spotify",
    });
    const user = await getFanProfileById(userId);
    console.log("user with new artists", user);
    console.log("followArtistsOnSpotify", selectedArtists);
    const artistsData = await syncAndUpdateSpotifyArtistData(
      selectedArtists,
      userId,
      userIpData,
      "FLYWHEEL"
    );
    console.log("artistsData", artistsData);

    let artistIds = [];
    let artistsSubscriptionList = [];
    artistsData?.forEach((e) => {
      artistIds.push(e.artist_spotfy_id);
      artistsSubscriptionList.push({
        list_id: e.rtsvrf_artist_id,
        list_name: `${e.artist_name}_all_followers`,
        list_description: `${e.artist_name}_all_followers`,
      });
    });

    if (artistIds.length) {
      const result = await updateProfileData(user.rtsvrf_fan_uuid, {
        spotify_followed_artists_id: [
          ...artistIds,
          ...user.spotify_followed_artists_id,
        ],
        artists_last_sync_timestamp: new Date(),
      });
      if (result.error) {
        toast.error(result.error.message ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await subscribeUserToMultipleArtists(
          user.rtsvrf_fan_uuid,
          artistsSubscriptionList
        );
        toast.success("Successfully followed new artists!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        await logSuccess("Sync Spotify", {
          ...userIpData,
          email: user?.fan_email,
          timestamp: new Date(),
          type: "Fan",
          details: `Synced Apple with ${artistsSubscriptionList?.length} followed artists`,
        });
      }
    }
    setLoading(false);
    closeModal();
    setEnableSpotifySyncPrompt(false);
    setIsSpotifySynced(false);
    reloadData();
  };

  const followArtistsOnApple = async () => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Sync Apple",
      action: "click",
      label: "Sync Apple",
    });
    const user = await getFanProfileById(userId);
    console.log("user with new artists", user);
    console.log("followArtistsOnApple", selectedArtists);
    const artistsData = await syncAndUpdateAppleArtistData(
      selectedArtists,
      userId,
      userIpData,
      "FLYWHEEL"
    );
    console.log("artistsData", artistsData);
    let artistsSubscriptionList = [];
    let followedAppleArtistList = [];
    let followedSpotifyArtistList = [];
    let followedRtsvrfArtistList = [];
    artistsData?.forEach((e) => {
      if (e.artist_aplmus_id) {
        followedAppleArtistList.push(e.artist_aplmus_id);
      } else {
        followedSpotifyArtistList.push(e.artist_spotfy_id);
      }
      followedRtsvrfArtistList.push(e.rtsvrf_artist_id);
      artistsSubscriptionList.push({
        list_id: e.rtsvrf_artist_id,
        list_name: `${e.artist_name}_all_followers`,
        list_description: `${e.artist_name}_all_followers`,
      });
    });

    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      apple_followed_artists_id: [
        ...(user?.apple_followed_artists_id ?? []),
        ...followedAppleArtistList,
      ],
      artists_last_sync_timestamp: new Date(),
      ...(followedSpotifyArtistList?.length
        ? {
            spotify_followed_artists_id: [
              ...(user?.spotify_followed_artists_id ?? []),
              ...followedSpotifyArtistList,
            ],
          }
        : {}),
    });
    if (result.error) {
      toast.error(result.error.message ?? "Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      await subscribeUserToMultipleArtists(
        user.rtsvrf_fan_uuid,
        artistsSubscriptionList
      );
      toast.success("Successfully followed new artists!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess("Sync Apple", {
        ...userIpData,
        email: user?.fan_email,
        timestamp: new Date(),
        type: "Fan",
        details: `Synced Apple with ${artistsSubscriptionList?.length} followed artists`,
      });
    }
    //

    setLoading(false);
    closeModal();
    setEnableSpotifySyncPrompt(false);
    setIsAppleSynced(false);
    reloadData();
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalCustomStyles}
        contentLabel="Sync Spotify Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">Update artist interests</h2>
          <span onClick={closeModal} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-4 text-black items-start">
          <h2 className="text-black text-sm py-3">
            {isSpotifySynced
              ? "Choose your favourite artists to follow"
              : "Sync artist interests from Spotify"}
          </h2>

          {isSpotifySynced ? (
            <>
              {spotifySyncedArtists.length ? (
                <>
                  <div className="w-full flex flex-col items-start justify-start max-h-96 overflow-y-auto">
                    {spotifySyncedArtists.map((artist) => (
                      <div class="mb-1 ml-6 flex items-start">
                        <input
                          id={artist.id}
                          type="checkbox"
                          value={artist.id}
                          name="selectedArtists"
                          checked={selectedArtists?.find(
                            (e) => e.id === artist.id
                          )}
                          onClick={() => handleSelectedArtists(artist)}
                          class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for={artist.id}
                          class="ml-2 text-xs  font-normal text-black"
                        >
                          {artist.name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => followArtistsOnSpotify()}
                    className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3 mt-2"
                  >
                    {loading ? "Following..." : "Follow Artists"}
                  </button>
                </>
              ) : (
                <h2 className="text-black text-sm py-3">
                  No top artists found to sync
                </h2>
              )}
            </>
          ) : null}

          {isAppleSynced ? (
            <>
              {appleSyncedArtists.length ? (
                <>
                  <div className="w-full flex flex-col items-start justify-start max-h-96 overflow-y-auto">
                    {appleSyncedArtists.map((artist) => (
                      <div class="mb-1 ml-6 flex items-start">
                        <input
                          id={artist.id}
                          type="checkbox"
                          value={artist.id}
                          name="selectedArtists"
                          checked={selectedArtists?.find(
                            (e) => e.id === artist.id
                          )}
                          onClick={() => handleSelectedArtists(artist)}
                          class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for={artist.id}
                          class="ml-2 text-xs  font-normal text-black"
                        >
                          {artist?.attributes?.name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => followArtistsOnApple()}
                    className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3 mt-2"
                  >
                    {loading ? "Following..." : "Follow Artists"}
                  </button>
                </>
              ) : (
                <h2 className="text-black text-sm py-3">
                  No top artists found to sync
                </h2>
              )}
            </>
          ) : null}

          {!isSpotifySynced && !isAppleSynced && (
            <div className="flex justify-around items-center">
              <button
                onClick={() => handleSyncSpotify()}
                className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3 mr-2"
              >
                {connecting || loading ? "Syncing..." : "Sync Spotify"}
              </button>
              <button
                onClick={() => handleAuthorizeApple()}
                className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3"
              >
                {connecting || loading ? "Syncing..." : "Sync Apple"}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default SyncSpotify;
