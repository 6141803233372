import React, { useEffect, useState } from "react";

import moment from "moment";
import Loading from "./Loading";

function Subscription({ fanSubscription, loading }) {
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    console.log(fanSubscription, "fanSubscription");
    setSubscription(fanSubscription);
  }, [fanSubscription]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {subscription ? (
        <div className="my-10 sm:w-full min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My AV Subscription
          </h3>
          <form class="w-full min-w-full max-w-xl bg-gray-200 shadow-xl rounded-lg p-4">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Status
                </label>
                <input
                  defaultValue={
                    subscription.rtsvrf_fan_subscription_status
                      ? "Active"
                      : "Inactive"
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Status"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Transaction ID
                </label>
                <input
                  defaultValue={
                    subscription.rtsvrf_fan_subscription_vendor_txn ?? "N/A"
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Transaction ID"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Vendor
                </label>
                <input
                  defaultValue={subscription.rtsvrf_fan_subscription_vendor}
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Vendor"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Type
                </label>
                <input
                  defaultValue={subscription.rtsvrf_fan_subscription_type}
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Type"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Start Date
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    subscription.subscription_start_date
                      ? moment(subscription.subscription_start_date).format(
                          "DD MMMM YYYY"
                        )
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription End Date
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    subscription.subscription_end_date
                      ? moment(subscription.subscription_end_date).format(
                          "DD MMMM YYYY"
                        )
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
            </div>

            <div class="w-full mb-6 ">
              <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                Subscription Status Update Date
              </label>
              <input
                class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                disabled={true}
                defaultValue={
                  subscription.fan_subscription_status_timestamp
                    ? moment(
                        subscription.fan_subscription_status_timestamp
                      ).format("LLL")
                    : ""
                }
                placeholder="Date"
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="my-10 sm:w-full flex items-center justify-center min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            No subscription found for the user
          </h3>
        </div>
      )}
    </>
  );
}

export default Subscription;
