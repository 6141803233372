import React, { useEffect, useState } from "react";
import { updateProfileData } from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import { toast } from "react-toastify";
import { modalCustomStyles } from "../rtsvrf-utils/Constants";
import { logSuccess } from "../rtsvrf-actions/Logging";
import ImageUpload from "./ImageUpload";

Modal.setAppElement("#root");

function EditProfileImage({ user, reloadData, userIpData }) {
  const [loading, setLoading] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleImageUpload = async (data) => {
    console.log(data, "dataaaa");
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Uplade profile picture",
      action: "click",
      label: "Uplade profile picture",
    });

    try {
      await updateProfileData(user.rtsvrf_fan_uuid, { fan_picture_link: data });
      await logSuccess(`Profile picture updated - ${user?.fan_name || ""}`, {
        ...userIpData,
        email: user?.fan_email,
        timestamp: new Date(),
        type: "Fan",
        details: `Profile picture updated via app`,
      });
      closeModal();
      if (reloadData) reloadData();
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <div class="p-2 text-center">
        <span onClick={openModal} className="underline text-sm cursor-pointer">
          Update Photo
        </span>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalCustomStyles}
        contentLabel="Profile Image Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">Update profile picture</h2>
          <span onClick={closeModal} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-1 w-full text-black items-center">
          <ImageUpload handleImageUpload={handleImageUpload} />
        </div>
      </Modal>
    </>
  );
}

export default EditProfileImage;
