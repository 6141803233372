import React, { useEffect, useState } from "react";
import { updateProfileData } from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import Account from "../rtsvrf-assets/icons/account.png";
import WalletIcon from "../rtsvrf-assets/icons/walletconnect.svg";
import { magic } from "../rtsvrf-utils/Magic";
import { toast } from "react-toastify";
import { modalCustomStyles } from "../rtsvrf-utils/Constants";
import { logSuccess } from "../rtsvrf-actions/Logging";

Modal.setAppElement("#root");

function WalletConnect({ user, reloadData, userIpData }) {
  const [loading, setLoading] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { address, connector, isConnected } = useAccount();
  // const { data: ensAvatar } = useEnsAvatar({ address });
  // const { data: ensName } = useEnsName({ address });
  const { connect, connectors, error, isLoading } = useConnect();
  const { disconnect } = useDisconnect();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const linkWalletConnect = async () => {
      if (isConnected && address) {
        const walletData = {
          wallet_address: address,
          wallet_updated_at: new Date(),
          wallet_type: "BYO",
          wallet_vendor: connector?.name || null,
        };
        disconnect();
        await handleConnection(walletData);
      }
    };

    linkWalletConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  const connectMagicLink = async () => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Create Wallet",
      action: "click",
      label: "Create Wallet",
    });

    await logSuccess(`Connect Wallet - Generated`, {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `Connected wallet with MagicLink`,
    });

    try {
      const accounts = await magic.wallet.connectWithUI();
      if (Array.isArray(accounts) && accounts.length) {
        console.log(accounts);
        const walletData = {
          wallet_address: accounts[0],
          wallet_updated_at: new Date(),
          wallet_type: "Generated",
          wallet_vendor: "MagicLink",
        };
        await handleConnection(walletData);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const handleWalletConnect = async (connector) => {
    ReactGA.event({
      category: "Link WalletConnect",
      action: "click",
      label: "Link WalletConnect",
    });
    await logSuccess(`Connect Wallet - BYO`, {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `Connected wallet with ${connector?.name || null}`,
    });
    connect({ connector });
  };

  const handleConnection = async (walletData) => {
    try {
      await updateProfileData(user.rtsvrf_fan_uuid, walletData);

      closeModal();
      reloadData();
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <div class="p-2">
        <div class="group relative w-max">
          <button
            onClick={openModal}
            class="flex text-white items-center font-bold bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center"
            type="button"
          >
            <img
              src={Account}
              alt="account"
              width={"30"}
              height={"30"}
              className="mr-1"
            />
            {user?.wallet_address
              ? user.wallet_address?.slice(0, 12) + "..."
              : "Connect Wallet"}
          </button>
          {user?.wallet_address && (
            <span class="pointer-events-none bg-black mt-1 text-white p-1 absolute bottom-15 rounded-md right-0 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100">
              {user.wallet_address}
            </span>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalCustomStyles}
        contentLabel="WalletConnect Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">
            {user?.wallet_address
              ? "Update your wallet"
              : "Connect your wallet"}
          </h2>
          <span onClick={closeModal} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-4 text-black items-center">
          {connectors.map((connector) => (
            <button
              disabled={!connector.ready}
              className="text-white flex items-center justify-between bg-gray-700 hover:bg-gray-800 rounded-lg text-sm px-2 py-2"
              key={connector.id}
              onClick={() => handleWalletConnect(connector)}
            >
              <img
                src={WalletIcon}
                alt="walletconnect"
                width={"40"}
                height={"40"}
              />
              {isLoading ? "Connecting..." : connector?.name}
              {!connector.ready && " (unsupported)"}
            </button>
          ))}
          {error && (
            <div className="text-red-700 text-sm mb-2">{error.message}</div>
          )}

          <h2 className="text-black text-sm py-3">
            {user?.wallet_address
              ? "Update your wallet by creating new one"
              : "No wallet? We will make one for you"}
          </h2>

          <button
            onClick={() => connectMagicLink()}
            className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3"
          >
            {loading
              ? "Creating..."
              : user?.wallet_address
              ? "Update Wallet"
              : "Create Wallet"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default WalletConnect;
