import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../rtsvrf-assets/avmainlogo.png";
import { getFanProfileById } from "../rtsvrf-actions/Actions";

const Header = ({ user }) => {
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setUserProfile(null);
        return;
      }
      setUserProfile(user);
    };
    fetchData();
  }, [user]);
  const redirectToUserProfile = () => {
    if (!userProfile) return;
    navigate(`/user/${userProfile.rtsvrf_fan_uuid}`);
  };

  return (
    <>
      <header className="App-header">
        <div className="w-full flex items-center justify-start">
          <div className="w-full flex items-center justify-start">
            <img
              src={logo}
              width={"60"}
              height={"60"}
              className="App-logo"
              alt="logo"
              align="left"
            />
            <h1 className="ml-2 w-full">ArtistVerified FanFeed App</h1>
          </div>
          {userProfile?.fan_picture_link && (
            <div onClick={() => redirectToUserProfile()}>
              <img
                src={userProfile.fan_picture_link}
                className="rounded-full cursor-pointer"
                width={"45"}
                height={"45"}
                alt="profile"
              />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
