export const shuffleArray = (array) => {
  //   return array.sort(() => Math.random() - 2);
  for (var i = array.length - 1; i > 0; i--) {
    // Generate random number
    var j = Math.floor(Math.random() * (i + 1));

    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export const isArrayEqual = (array1, array2) => {
  if (array1.length !== array2.length) return false;

  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i])) return false;
  }

  return true;
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const promptSyncSpotify = (type, lastSync) => {
  if (!type && !lastSync) return false;
  if (type && !lastSync) return true;
  const currentDate = new Date();
  const nextMonthDate = new Date(lastSync);
  const nextWeekDate = new Date(lastSync);

  nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

  nextWeekDate.setDate(nextWeekDate.getDate() + 7);

  if (type === "Monthly" && currentDate >= nextMonthDate) {
    // Prompt notification for Monthly sync older than one month
    return true;
  } else if (type === "Weekly" && currentDate >= nextWeekDate) {
    // Prompt notification for Weekly sync older than one week
    return true;
  } else if (type === "Never") {
    return false;
  }
  return false;
};
