import React, { useEffect, useState } from "react";

export const Tabs = ({ children, setTab }) => {
  function findActiveTab(a) {
    return a.reduce((accumulator, currentValue, i) => {
      if (currentValue.props.active) {
        return i;
      }

      return accumulator;
    }, 0);
  }

  function tabValidator(tab) {
    return tab.type.displayName === "Tab" ? true : false;
  }

  const [activeTab, setActiveTab] = useState(findActiveTab(children));
  useEffect(() => {
    setActiveTab(findActiveTab(children));
  }, [children]);
  return (
    <>
      <div className="flex sm:flex-col gap-2 w-full rounded-md overflow-x-scroll whitespace-nowrap justify-start bg-gray-300 p-2">
        {children.map((item, i) => {
          return (
            <>
              {tabValidator(item) && (
                <Tab
                  {...item.props}
                  key={`tab-{i}`}
                  currentTab={i}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                >
                  {item.props.children}
                </Tab>
              )}
            </>
          );
        })}
      </div>
      <div className="p-5 md:p-1">
        {children.map((item, i) => {
          return (
            <div className={` ${i === activeTab ? "visible" : "hidden"}`}>
              {item.props.component}
            </div>
          );
        })}
      </div>
    </>
  );
};

export function Tab({
  children,
  activeTab,
  currentTab,
  setActiveTab,
  handleTab,
}) {
  return (
    <>
      <div
        className={`px-5 py-3 rounded cursor-pointer
      ${activeTab === currentTab ? "bg-white" : "bg-gray-400 text-white"}`}
        onClick={() => {
          setActiveTab(currentTab);
          handleTab && handleTab(currentTab);
        }}
      >
        {children}
      </div>
    </>
  );
}

Tab.displayName = "Tab";
