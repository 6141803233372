import React from "react";

import Loading from "../rtsvrf-components/Loading";

import EditProfile from "../rtsvrf-components/EditProfile";
import SelectArtists from "../rtsvrf-components/SelectArtists";
import { Tab, Tabs } from "../rtsvrf-components/Tabs";
import FollowArtists from "./FollowArtists";
import ListArtists from "./ListArtists";
import {
  unsubscribeUserFromArtist,
  updateProfileData,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";

function ArtistRelations({
  userIpData,
  user,
  reloadData,
  loading,
  favouriteArtists,
  favouriteArtistImages,
  defaultArtists,
  handleArtistSelection,
  editFavArtist1,
  setEditFavArtist1,
  editFavArtist2,
  setEditFavArtist2,
  editFavArtist3,
  setEditFavArtist3,

  editFavArtist4,
  setEditFavArtist4,

  editFavArtist5,
  setEditFavArtist5,
}) {
  const unfollowArtistOnSpotify = async (artist) => {
    console.log(
      "unfollow on spotify",
      user.spotify_followed_artists_id,
      user.spotify_followed_artists_id.filter(
        (e) => e !== artist.artist_spotfy_id
      )
    );
    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      spotify_followed_artists_id: user.spotify_followed_artists_id.filter(
        (e) => e !== artist.artist_spotfy_id
      ),
    });
    return result;
  };

  const unfollowArtistOnApple = async (artist) => {
    console.log("unfollow on apple", artist.artist_aplmus_id);
    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      apple_followed_artists_id: user.apple_followed_artists_id.filter(
        (e) => e !== artist.artist_aplmus_id
      ),
    });
    return result;
  };

  const unfollowArtist = async (selectedArtist) => {
    ReactGA.event({
      category: "Unfollow artist",
      action: "click",
      label: "Unfollow artist",
    });
    console.log("unfollow selectedArtist", selectedArtist);
    if (selectedArtist) {
      let result = null;
      if (
        selectedArtist.artist_spotfy_id &&
        user?.spotify_followed_artists_id?.includes(
          selectedArtist.artist_spotfy_id
        )
      ) {
        result = await unfollowArtistOnSpotify(selectedArtist);
      }
      if (
        selectedArtist.artist_aplmus_id &&
        user?.apple_followed_artists_id?.includes(
          selectedArtist.artist_aplmus_id
        )
      ) {
        result = await unfollowArtistOnApple(selectedArtist);
      }
      if (result?.error) {
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      toast.success("Unfollowed artist successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await unsubscribeUserFromArtist(
        user.rtsvrf_fan_uuid,
        selectedArtist.rtsvrf_artist_id
      );
      await logSuccess("Unfollow Artist", {
        ...userIpData,
        email: user?.fan_email,
        timestamp: new Date(),
        type: "Fan",
        details: `Unfollowed artist - ${selectedArtist.artist_name} and unsubscribed to ${selectedArtist.artist_name}_all_followers list for messaging`,
      });

      reloadData();
    } else {
      toast.error("Please select an artist to unfollow");
    }
  };

  return (
    <div className="my-20 ml-2">
      <Tabs>
        <Tab
          component={
            <div className="flex md:flex-col min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user ? (
                <ListArtists user={user} unfollowArtist={unfollowArtist} />
              ) : (
                <Loading />
              )}
            </div>
          }
        >
          Following
        </Tab>
        <Tab
          active
          component={
            <div className="flex md:flex-col min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user ? (
                <FollowArtists
                  user={user}
                  loading={loading}
                  userIpData={userIpData}
                  reloadData={reloadData}
                  defaultArtists={defaultArtists}
                  unfollowArtist={unfollowArtist}
                />
              ) : (
                <Loading />
              )}
            </div>
          }
        >
          Discover
        </Tab>
        <Tab
          component={
            <div className="flex md:flex-col flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user ? (
                <SelectArtists
                  user={user}
                  loading={loading}
                  userIpData={userIpData}
                  favouriteArtists={favouriteArtists}
                  favouriteArtistImages={favouriteArtistImages}
                  defaultArtists={defaultArtists}
                  handleArtistSelection={handleArtistSelection}
                  editFavArtist1={editFavArtist1}
                  setEditFavArtist1={setEditFavArtist1}
                  editFavArtist2={editFavArtist2}
                  setEditFavArtist2={setEditFavArtist2}
                  editFavArtist3={editFavArtist3}
                  setEditFavArtist3={setEditFavArtist3}
                  editFavArtist4={editFavArtist4}
                  setEditFavArtist4={setEditFavArtist4}
                  editFavArtist5={editFavArtist5}
                  setEditFavArtist5={setEditFavArtist5}
                />
              ) : (
                <Loading />
              )}
            </div>
          }
        >
          Favourites
        </Tab>
      </Tabs>
    </div>
  );
}

export default ArtistRelations;
