import React, { useEffect, useState } from "react";
import EditIcon from "../rtsvrf-assets/icons/pencil.png";
import Favourite from "../rtsvrf-assets/icons/top-rated.png";

import SelectArtist from "./SelectArtist";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";

function SelectArtists({
  user,
  userIpData,
  loading,
  favouriteArtists,
  favouriteArtistImages,
  defaultArtists,
  handleArtistSelection,
  editFavArtist1,
  setEditFavArtist1,
  editFavArtist2,
  setEditFavArtist2,
  editFavArtist3,
  setEditFavArtist3,

  editFavArtist4,
  setEditFavArtist4,

  editFavArtist5,
  setEditFavArtist5,
}) {
  const [favArtist1, setFavArtist1] = useState(null);
  const [favArtist2, setFavArtist2] = useState(null);
  const [favArtist3, setFavArtist3] = useState(null);
  const [favArtistImage1, setFavArtistImage1] = useState(null);
  const [favArtistImage2, setFavArtistImage2] = useState(null);
  const [favArtistImage3, setFavArtistImage3] = useState(null);

  const [favArtist4, setFavArtist4] = useState(null);
  const [favArtistImage4, setFavArtistImage4] = useState(null);

  const [favArtist5, setFavArtist5] = useState(null);
  const [favArtistImage5, setFavArtistImage5] = useState(null);

  useEffect(() => {
    if (favouriteArtists?.length && user) {
      const fav1 = favouriteArtists.find(
        (artist) => artist.rtsvrf_artist_id === user["favourite_1"]
      );
      if (fav1) setFavArtist1(fav1);
      else setFavArtist1(null);

      const fav2 = favouriteArtists.find(
        (artist) => artist.rtsvrf_artist_id === user["favourite_2"]
      );
      if (fav2) setFavArtist2(fav2);
      else setFavArtist2(null);

      const fav3 = favouriteArtists.find(
        (artist) => artist.rtsvrf_artist_id === user["favourite_3"]
      );
      if (fav3) setFavArtist3(fav3);
      else setFavArtist3(null);

      const fav4 = favouriteArtists.find(
        (artist) => artist.rtsvrf_artist_id === user["favourite_4"]
      );
      if (fav4) setFavArtist4(fav4);
      else setFavArtist4(null);

      const fav5 = favouriteArtists.find(
        (artist) => artist.rtsvrf_artist_id === user["favourite_5"]
      );
      if (fav5) setFavArtist5(fav5);
      else setFavArtist5(null);
    } else {
      setFavArtist1(null);
      setFavArtist2(null);
      setFavArtist3(null);
      setFavArtist4(null);
      setFavArtist5(null);
    }
  }, [favouriteArtists, user]);
  useEffect(() => {
    if (favouriteArtistImages?.length) {
      if (favArtist1) {
        const fav1 = favouriteArtistImages.find(
          (artist) => artist.rtsvrf_artist_id === favArtist1?.rtsvrf_artist_id
        );
        if (fav1) setFavArtistImage1(fav1[fav1.artist_picture_preference]);
        else setFavArtistImage1(null);
      }
      if (favArtist2) {
        const fav2 = favouriteArtistImages.find(
          (artist) => artist.rtsvrf_artist_id === favArtist2?.rtsvrf_artist_id
        );
        if (fav2) setFavArtistImage2(fav2[fav2.artist_picture_preference]);
        else setFavArtistImage2(null);
      }
      if (favArtist3) {
        const fav3 = favouriteArtistImages.find(
          (artist) => artist.rtsvrf_artist_id === favArtist3?.rtsvrf_artist_id
        );
        if (fav3) setFavArtistImage3(fav3[fav3.artist_picture_preference]);
        else setFavArtistImage3(null);
      }

      if (favArtist4) {
        const fav4 = favouriteArtistImages.find(
          (artist) => artist.rtsvrf_artist_id === favArtist4?.rtsvrf_artist_id
        );
        if (fav4) setFavArtistImage4(fav4[fav4.artist_picture_preference]);
        else setFavArtistImage4(null);
      }

      if (favArtist5) {
        const fav5 = favouriteArtistImages.find(
          (artist) => artist.rtsvrf_artist_id === favArtist5?.rtsvrf_artist_id
        );
        if (fav5) setFavArtistImage5(fav5[fav5.artist_picture_preference]);
        else setFavArtistImage5(null);
      }
    } else {
      setFavArtistImage1(null);
      setFavArtistImage2(null);
      setFavArtistImage3(null);
    }
  }, [
    favArtist1,
    favArtist2,
    favArtist3,
    favArtist4,
    favArtist5,
    favouriteArtistImages,
  ]);

  const selectFavouriteArtist = async (selectedOption, name) => {
    if (
      favouriteArtists?.find(
        (artist) => artist.rtsvrf_artist_id === selectedOption.rtsvrf_artist_id
      )
    ) {
      toast.error("Select unique artist!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    await logSuccess(`Favourite artist changed - ${user?.fan_name}`, {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `${selectedOption?.artist_name} has been updated as ${name} artist`,
    });
    handleArtistSelection(selectedOption, name);
  };

  return (
    <div className="my-2 sm:w-full min-2xl:ml-28">
      {user && !loading ? (
        <div className="flex flex-col">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My Artist Relationships
          </h3>

          <div className="flex flex-col justify-center items-center bg-gray-200 shadow-xl rounded-lg p-4">
            <div className="text-left md:my-3 sm:w-full w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              {favArtist1 && !editFavArtist1 ? (
                <div class="flex items-center md:items-start">
                  <img
                    src={Favourite}
                    alt="fav"
                    width={"45"}
                    className="mr-1.5"
                    height={"45"}
                  />
                  {favArtistImage1 && (
                    <img
                      src={favArtistImage1}
                      alt="fav"
                      style={{ borderRadius: "50%" }}
                      className="mr-1.5 w-11 h-11"
                    />
                  )}
                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold flex items-center leading-tight text-gray-500">
                      Favourite Artist 1
                      <span
                        className="ml-auto cursor-pointer"
                        onClick={() => setEditFavArtist1(true)}
                      >
                        <img src={EditIcon} alt="edit" width="15" height="15" />
                      </span>
                    </h4>
                    <h3 class="text-gray-600 text-xs">
                      {favArtist1?.artist_name}
                    </h3>
                  </div>
                </div>
              ) : (
                <SelectArtist
                  artistData={defaultArtists}
                  name={"favourite_1"}
                  selectedArtist={user["favourite_1"]}
                  handleArtistSelection={selectFavouriteArtist}
                />
              )}
            </div>
            <div className="text-left md:my-3 sm:w-full  w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              {favArtist2 && !editFavArtist2 ? (
                <div class="flex items-center md:items-start">
                  <img
                    src={Favourite}
                    alt="fav"
                    width={"45"}
                    className="mr-1.5"
                    height={"45"}
                  />
                  {favArtistImage2 && (
                    <img
                      src={favArtistImage2}
                      alt="fav"
                      style={{ borderRadius: "50%" }}
                      className="mr-1.5 w-11 h-11"
                    />
                  )}
                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold flex items-center leading-tight text-gray-500">
                      Favourite Artist 2
                      <span
                        className="ml-auto cursor-pointer"
                        onClick={() => setEditFavArtist2(true)}
                      >
                        <img src={EditIcon} alt="edit" width="15" height="15" />
                      </span>
                    </h4>
                    <h3 class="text-gray-600 text-xs">
                      {favArtist2?.artist_name}
                    </h3>
                  </div>
                </div>
              ) : (
                <SelectArtist
                  artistData={defaultArtists}
                  name={"favourite_2"}
                  selectedArtist={user["favourite_2"]}
                  handleArtistSelection={selectFavouriteArtist}
                />
              )}
            </div>
            <div className="text-left md:my-3 sm:w-full  w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              {favArtist3 && !editFavArtist3 ? (
                <div class="flex items-center md:items-start">
                  <img
                    src={Favourite}
                    alt="fav"
                    width={"45"}
                    className="mr-1.5"
                    height={"45"}
                  />
                  {favArtistImage3 && (
                    <img
                      src={favArtistImage3}
                      alt="fav"
                      style={{ borderRadius: "50%" }}
                      className="mr-1.5 w-11 h-11"
                    />
                  )}
                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold leading-tight flex items-center text-gray-500">
                      Favourite Artist 3
                      <span
                        className="ml-auto cursor-pointer"
                        onClick={() => setEditFavArtist3(true)}
                      >
                        <img src={EditIcon} alt="edit" width="15" height="15" />
                      </span>
                    </h4>
                    <h3 class="text-gray-600 text-xs">
                      {favArtist3?.artist_name}
                    </h3>
                  </div>
                </div>
              ) : (
                <SelectArtist
                  artistData={defaultArtists}
                  name={"favourite_3"}
                  selectedArtist={user["favourite_3"]}
                  handleArtistSelection={selectFavouriteArtist}
                />
              )}
            </div>

            <div className="text-left md:my-3 sm:w-full  w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              {favArtist4 && !editFavArtist4 ? (
                <div class="flex items-center md:items-start">
                  <img
                    src={Favourite}
                    alt="fav"
                    width={"45"}
                    className="mr-1.5"
                    height={"45"}
                  />
                  {favArtistImage4 && (
                    <img
                      src={favArtistImage4}
                      alt="fav"
                      style={{ borderRadius: "50%" }}
                      className="mr-1.5 w-11 h-11"
                    />
                  )}
                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold flex items-center leading-tight text-gray-500">
                      Favourite Artist 4
                      <span
                        className="ml-auto cursor-pointer"
                        onClick={() => setEditFavArtist4(true)}
                      >
                        <img src={EditIcon} alt="edit" width="15" height="15" />
                      </span>
                    </h4>
                    <h3 class="text-gray-600 text-xs">
                      {favArtist4?.artist_name}
                    </h3>
                  </div>
                </div>
              ) : (
                <SelectArtist
                  artistData={defaultArtists}
                  name={"favourite_4"}
                  selectedArtist={user["favourite_4"]}
                  handleArtistSelection={selectFavouriteArtist}
                />
              )}
            </div>

            <div className="text-left md:my-3 sm:w-full  w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              {favArtist5 && !editFavArtist5 ? (
                <div class="flex items-center md:items-start">
                  <img
                    src={Favourite}
                    alt="fav"
                    width={"45"}
                    className="mr-1.5"
                    height={"45"}
                  />
                  {favArtistImage5 && (
                    <img
                      src={favArtistImage5}
                      alt="fav"
                      style={{ borderRadius: "50%" }}
                      className="mr-1.5 w-11 h-11"
                    />
                  )}
                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold flex items-center leading-tight text-gray-500">
                      Favourite Artist 5
                      <span
                        className="ml-auto cursor-pointer"
                        onClick={() => setEditFavArtist5(true)}
                      >
                        <img src={EditIcon} alt="edit" width="15" height="15" />
                      </span>
                    </h4>
                    <h3 class="text-gray-600 text-xs">
                      {favArtist5?.artist_name}
                    </h3>
                  </div>
                </div>
              ) : (
                <SelectArtist
                  artistData={defaultArtists}
                  name={"favourite_5"}
                  selectedArtist={user["favourite_5"]}
                  handleArtistSelection={selectFavouriteArtist}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-around items-center bg-white shadow-xl rounded-lg w-80 h-80 p-2 mt-10">
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectArtists;
