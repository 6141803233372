import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import home from "../rtsvrf-assets/icons/house.png";
import discover from "../rtsvrf-assets/icons/discover1.png";
import following from "../rtsvrf-assets/icons/followers.png";
import { getFanProfileById } from "../rtsvrf-actions/Actions";
import AvInbox from "./Inbox";
import {
  HomePagePreference,
  ProfilePagePreference,
} from "../rtsvrf-utils/Constants";

const Footer = ({ user }) => {
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setUserProfile(null);
        return;
      }
      setUserProfile(user);
    };
    fetchData();
  }, [user]);
  const redirectToHome = () => {
    if (!userProfile) return;
    navigate(
      `/feed/${userProfile.rtsvrf_fan_uuid}?tab=${userProfile?.homepage_preference}`
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const redirectToFollowing = () => {
    if (!userProfile) return;
    navigate(
      `/feed/${userProfile.rtsvrf_fan_uuid}?tab=${HomePagePreference.FollowedArtists}`
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const redirectToDiscover = () => {
    if (!userProfile) return;
    navigate(
      `/user/${userProfile.rtsvrf_fan_uuid}?tab=${ProfilePagePreference.ArtistRelations}`
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // const redirectToHome = () => {
  //   if (!userProfile) return;
  //   window.location.replace(
  //     `${window.location.origin}/feed/${userProfile.rtsvrf_fan_uuid}?tab=${userProfile?.homepage_preference}`
  //   );
  // };

  // const redirectToFollowing = () => {
  //   if (!userProfile) return;

  //   window.location.replace(
  //     `${window.location.origin}/feed/${userProfile.rtsvrf_fan_uuid}?tab=${HomePagePreference.FollowedArtists}`
  //   );
  // };

  // const redirectToDiscover = () => {
  //   if (!userProfile) return;
  //   window.location.replace(
  //     `${window.location.origin}/user/${userProfile.rtsvrf_fan_uuid}?tab=${ProfilePagePreference.ArtistRelations}`
  //   );
  // };

  return (
    <>
      {userProfile && (
        <footer className="app-footer p-2 flex justify-around items-center sticky bottom-0">
          <button
            className="bg-white text-black rounded-full p-3"
            onClick={() => redirectToHome()}
          >
            <img src={home} alt="bell" width={"24"} height={"24"} />
          </button>
          <button
            onClick={() => redirectToFollowing()}
            className="bg-white text-black rounded-full p-3"
          >
            <img src={following} alt="bell" width={"24"} height={"24"} />
          </button>
          <button
            onClick={() => redirectToDiscover()}
            className="bg-white text-black rounded-full p-3"
          >
            <img src={discover} alt="bell" width={"24"} height={"24"} />
          </button>
          <AvInbox
            userId={userProfile?.rtsvrf_fan_uuid}
            subscriberId={userProfile?.suprsend_subscriber_id}
          />
        </footer>
      )}
    </>
  );
};

export default Footer;
