/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import footerlogo from "./rtsvrf-assets/avfooter.jpg";
import ReactGA from "react-ga4";
import "./App.css";
import Fanfeed from "./rtsvrf-pages/Fanfeed";
import Home from "./rtsvrf-pages/Home";
import SpotifyWebApi from "spotify-web-api-js";
import User from "./rtsvrf-pages/User";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import suprsend from "@suprsend/web-sdk";
import { getUserAppleJwtToken } from "./rtsvrf-actions/Actions";
import AccessError from "./rtsvrf-pages/AccessError";
import RedirectPage from "./rtsvrf-pages/RedirectPage";
import Header from "./rtsvrf-components/Header";

suprsend.init(
  process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY,
  process.env.REACT_APP_SUPRSEND_WORKSPACE_SECRET
);

const App = () => {
  const [userIpData, setUserIpData] = useState();
  const [music, setMusic] = useState();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-P733QLC72X", {
      gaOptions: { streamId: "5219543421" },
    });

    const setAppleMusicKit = async () => {
      const result = await getUserAppleJwtToken();
      console.log(result, "apple token");

      console.log("apple musickit loaded");
      // Call configure() to configure an instance of MusicKit on the Web.
      try {
        const result = await getUserAppleJwtToken();
        console.log(result, "apple token");
        await window.MusicKit?.configure({
          developerToken: result.token,
          app: {
            name: "Fan app",
            build: "1978.4.1",
          },
        });
        // MusicKit instance is available
        const musicInstance = MusicKit.getInstance();
        console.log(musicInstance, "apple music");
        setMusic(musicInstance);
      } catch (err) {
        console.log(err, "error");
        // Handle configuration error
      }
    };
    setAppleMusicKit();
  }, []);

  useEffect(() => {
    const getIp = async () => {
      const response = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_KEY}`
      );
      const data = await response.json();
      console.log(data, "geo data");
      setUserIpData({
        ip_address: data.ip,
        user_agent: navigator.userAgent,
      });
    };
    getIp();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    const hash = window.location.hash
      .substring(1)
      .split("&")
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split("=");
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});
    window.location.hash = "";
    console.log(hash.access_token, "Hash token", window.location.href);
    if (hash.access_token) {
      localStorage.setItem("spotifyToken", hash.access_token);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Home music={music} userIpData={userIpData} />}
        />
        <Route
          path="/redirect"
          element={<RedirectPage music={music} userIpData={userIpData} />}
        />
        <Route
          path="/feed/:id"
          element={<Fanfeed music={music} userIpData={userIpData} />}
        />
        <Route path="/user/:id" element={<User userIpData={userIpData} />} />
        <Route
          path="/user/404"
          element={<AccessError userIpData={userIpData} />}
        />
      </Routes>

      <center className="my-4">
        <img src={footerlogo} className="App-logo" alt="footerlogo" />
        <br />
        Copyright &copy; 2023 ArtistVerified Inc.
      </center>
      <ToastContainer />
    </>
  );
};

export default App;
