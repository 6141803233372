import React from "react";
import { CONNECT_SPOTIFY, PROFILE_LINK } from "../rtsvrf-utils/Constants";
import Spotify from "../rtsvrf-assets/icons/spotify.png";
import Apple from "../rtsvrf-assets/icons/apple.png";
import verified from "../rtsvrf-assets/icons/av-tick.png";
import artistIcon from "../rtsvrf-assets/icons/account.png";

function Artists({
  followedArtists,
  artistImages,
  defaultView,
  allowRedirect,
  spotifyConnection,
  handleSpotifyConnection,
  authorizeAppleMusic,
  user,
  connecting,
  loading,
}) {
  const redirectToProfile = (artist) => {
    if (allowRedirect) {
      window.location.href = `${PROFILE_LINK}/${artist?.rtsvrf_artist_id}`;
    }
  };

  return (
    <>
      {followedArtists?.length ? (
        <div
          className={`flex flex-col ml-auto mr-auto items-center ${
            defaultView && "my-8"
          }`}
        >
          {defaultView && (
            <h2 className="text-xl my-2 text-center font-bold text-white">
              These are just some of the music artists you'll find here ...
            </h2>
          )}

          <ul className="flex flex-wrap sm:flex-col item-center justify-center">
            {followedArtists.map((artist) => {
              const artistImageData = artistImages?.find(
                (img) => img.rtsvrf_artist_id === artist?.rtsvrf_artist_id
              );

              const image = artistImageData
                ? artistImageData[artistImageData.artist_picture_preference]
                : null;
              if (!image && defaultView) return null;
              return (
                <div
                  key={artist?.rtsvrf_artist_id}
                  onClick={() => redirectToProfile(artist)}
                  className={
                    defaultView
                      ? "relative px-6 py-4 m-2 w-44 cursor-pointer bg-bisque opacity-75 border border-bisque rounded-lg shadow"
                      : "relative px-6 py-4 m-2 w-44 cursor-pointer bg-gray-700 border border-gray-700 rounded-lg shadow"
                  }
                >
                  {artist?.rtsvrf_profile_claim_status && (
                    <span className="absolute top-1 right-1">
                      <img
                        className="ml-1"
                        width={"15"}
                        height={"15"}
                        alt="verified"
                        src={verified}
                      />
                    </span>
                  )}
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="cursor-pointer w-28 h-28 mb-3 rounded-full shadow-lg"
                      src={image || artistIcon}
                      alt="img"
                    />

                    <h5
                      // onClick={(event) => redirectToProfile(event, artist)}
                      className={`flex cursor-pointer mb-1 text-xl text-center font-medium  ${
                        defaultView ? "text-black" : "text-white"
                      }`}
                    >
                      {artist?.artist_name}
                    </h5>
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      ) : !defaultView && !loading && followedArtists?.length === 0 ? (
        <div className="flex flex-col items-center my-2">
          <p className="mt-4 text-center flex items-center h-48 text-3xl font-extrabold text-gray-900 dark:text-white  text-transparent bg-clip-text bg-gradient-to-r to-red-800 from-red-600">
            Follow your favorite artists on Spotify or Add your favourite
            artists to your Apple Music Library so they appear here
          </p>

          {!spotifyConnection && (
            <button
              className="w-60 flex my-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
              onClick={() => handleSpotifyConnection(CONNECT_SPOTIFY)}
            >
              Connect With{" "}
              <span className="ml-1">
                <img width={"85"} height={"20"} src={Spotify} alt="icon" />
              </span>
            </button>
          )}
          {connecting ? (
            <button className="w-60 flex my-8 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              Connecting...
            </button>
          ) : (
            <button
              className="w-60 flex my-8 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
              onClick={authorizeAppleMusic}
            >
              Connect With{" "}
              <span className="mx-1">
                <img width={"25"} height={"25"} src={Apple} alt="icon" />
              </span>{" "}
              Apple
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}

export default Artists;
