import React, { useState } from "react";
import ReactGA from "react-ga4";
import SelectArtist from "./SelectArtist";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import {
  getArtistImage,
  subscribeUserToArtist,
  updateProfileData,
} from "../rtsvrf-actions/Actions";
import { SPOTIFY_UNKNOWN } from "../rtsvrf-utils/Constants";

function FollowArtists({
  user,
  userIpData,
  loading,
  defaultArtists,
  reloadData,
  unfollowArtist,
}) {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedArtistImage, setSelectedArtistImage] = useState(null);
  const searchArtist = async (selectedOption, name) => {
    console.log("selectedOption", selectedOption, name);
    setSelectedArtist(selectedOption);
    if (selectedOption) {
      const images = await getArtistImage([selectedOption.rtsvrf_artist_id]);
      console.log("images", images);
      if (images?.[0]) {
        setSelectedArtistImage(
          images[0]?.[images[0]["artist_picture_preference"]]
        );
      } else {
        setSelectedArtistImage(null);
      }
    }
  };

  const followArtist = async () => {
    ReactGA.event({
      category: "Follow artist",
      action: "click",
      label: "Follow artist",
    });
    console.log("selectedArtist", selectedArtist);
    if (selectedArtist) {
      const subscription = {
        list_id: selectedArtist.rtsvrf_artist_id,
        list_name: `${selectedArtist.artist_name}_all_followers`,
        list_description: `${selectedArtist.artist_name}_all_followers`,
      };
      console.log("subscription", subscription);
      let result = null;
      if (
        selectedArtist.artist_spotfy_id &&
        !selectedArtist.artist_spotfy_id?.includes(SPOTIFY_UNKNOWN)
      ) {
        result = await followArtistOnSpotify(selectedArtist);
      } else if (selectedArtist.artist_aplmus_id) {
        result = await followArtistOnApple(selectedArtist);
      }
      if (result?.error) {
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      toast.success("Followed artist successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await subscribeUserToArtist(
        user.rtsvrf_fan_uuid,
        selectedArtist.rtsvrf_artist_id,
        subscription
      );
      await logSuccess("Follow Artist", {
        ...userIpData,
        email: user?.fan_email,
        timestamp: new Date(),
        type: "Fan",
        details: `Followed artist - ${selectedArtist.artist_name} and subscribed to ${selectedArtist.artist_name}_all_followers list for messaging`,
      });
      setSelectedArtist(null);
      reloadData();
    } else {
      toast.error("Please select an artist to follow");
    }
  };

  const followArtistOnSpotify = async (artist) => {
    console.log("follow on spotify", artist.artist_spotfy_id);
    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      spotify_followed_artists_id: [
        artist.artist_spotfy_id,
        ...(user.spotify_followed_artists_id ?? []),
      ],
    });
    return result;
  };

  const followArtistOnApple = async (artist) => {
    console.log("follow on apple", artist.artist_aplmus_id);
    const result = await updateProfileData(user.rtsvrf_fan_uuid, {
      apple_followed_artists_id: [
        artist.artist_aplmus_id,
        ...(user.apple_followed_artists_id ?? []),
      ],
    });
    return result;
  };

  const onUnfollowArtist = async (selectedArtist) => {
    console.log("unfollow selectedArtist", selectedArtist);
    await unfollowArtist(selectedArtist);
    setSelectedArtist(null);
  };

  return (
    <div className="my-2 sm:w-full min-2xl:ml-28">
      {user && !loading ? (
        <div className="flex flex-col">
          <div className="flex flex-col justify-center items-center bg-gray-200 shadow-xl rounded-lg p-4">
            <div className="text-left md:my-3 sm:w-full w-72 bg-white shadow-xl rounded-lg p-2 my-2">
              <h3 className="text-sm my-1 font-bold text-gray-500">
                Find Artist:
              </h3>
              <SelectArtist
                isClearable={true}
                artistData={defaultArtists}
                name={"search_artist"}
                selectedArtist={selectedArtist}
                handleArtistSelection={searchArtist}
              />

              {selectedArtist && (
                <div class="flex items-center md:items-start mt-2">
                  {selectedArtistImage && (
                    <img
                      src={selectedArtistImage}
                      alt="fav"
                      width={"45"}
                      className="mr-1.5"
                      height={"45"}
                    />
                  )}

                  <div class="text-md flex flex-col w-full">
                    <h4 class="mb-2 mt-0 text-xs font-bold flex items-center leading-tight text-gray-500">
                      {selectedArtist?.artist_name}
                      <span
                        className="ml-auto cursor-pointer"
                        //
                      >
                        {user?.spotify_followed_artists_id?.includes(
                          selectedArtist?.artist_spotfy_id
                        ) ||
                        user?.apple_followed_artists_id?.includes(
                          selectedArtist?.artist_aplmus_id
                        ) ? (
                          <button
                            className="hover:bg-black hover:text-white p-2 hover:rounded-lg"
                            onClick={() => onUnfollowArtist(selectedArtist)}
                          >
                            Unfollow
                          </button>
                        ) : (
                          <button
                            className="hover:bg-black hover:text-white p-2 hover:rounded-lg"
                            onClick={() => followArtist()}
                          >
                            Follow
                          </button>
                        )}
                      </span>
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <h3 className="max-w-sm sm:max-w-none text-center text-lg my-2 font-bold text-white">
              Recommended Artists
            </h3>
            <p className="text-center">No data found</p>
          </div>
        </div>
      ) : (
        <div className="flex justify-around items-center bg-white shadow-xl rounded-lg w-80 h-80 p-2 mt-10">
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default FollowArtists;
