import React, { useEffect, useState } from "react";
import {
  getCountryNames,
  getCountryPhoneNumberCodes,
  updateProfileData,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import moment from "moment";
import { toast } from "react-toastify";
import suprsend from "@suprsend/web-sdk";
import { logSuccess } from "../rtsvrf-actions/Logging";

function EditProfile({ user, reloadData, userIpData }) {
  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState(user);

  useEffect(() => {
    getCountryNames().then((res) => {
      if (res) setCountries(res);
    });
    getCountryPhoneNumberCodes().then((res) => {
      console.log(res, "res");
      if (res) setCountryCodes(res);
    });
  }, []);

  useEffect(() => {
    setProfile(user);
  }, [user]);

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(100), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save profile",
      action: "click",
      label: "Save profile",
    });
    const difference = Object.keys(profile).filter(
      (k) =>
        profile[k] !== user[k] &&
        k !== "spotify_followed_artists_id" &&
        k !== "apple_followed_artists_id"
    );
    if (difference?.includes("fan_name")) {
      if (profile.fan_name) suprsend.user.set("name", profile.fan_name);
      else suprsend.user.unset("name");
    }
    if (
      difference?.includes("phone_number") ||
      difference?.includes("dial_prefix")
    ) {
      if (profile.phone_number && profile.dial_prefix)
        suprsend.user.add_sms(profile.dial_prefix + profile.phone_number);
      else suprsend.user.remove_sms(user.dial_prefix + user.phone_number);
    }

    const details =
      difference?.map(
        (field) => `${field} changed from ${user[field]} to ${profile[field]}`
      ) || [];
    await logSuccess(`Profile updated - ${user?.fan_name}`, {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: details.join(","),
    });
    const result = await updateProfileData(user.rtsvrf_fan_uuid, profile);
    setLoading(false);
    if (!result.error) {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      reloadData();
    }
  };

  return (
    <>
      {profile ? (
        <div className="my-10 sm:w-full min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My Details
          </h3>
          <form class="w-full max-w-lg bg-gray-200 shadow-xl rounded-lg p-4">
            <div class="w-full mb-6">
              <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Full Name
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                defaultValue={profile.fan_name}
                onChange={(e) => handleForm("fan_name", e.target.value)}
                placeholder="John Doe"
                required
              />
            </div>

            <div class="w-full mb-6">
              <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Gender
              </label>
              <div class="relative">
                <select
                  placeholder="Select"
                  defaultValue={profile.gender}
                  onChange={(e) => handleForm("gender", e.target.value)}
                  class="block appearance-none text-xs w-full bg-white border border-gray-200 text-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                  <option value={"Non-Binary"}>Non-Binary</option>
                  <option value={"Not Specified"}>Not Specified</option>
                </select>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/3 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Birth Year
                </label>
                <div class="relative">
                  <select
                    onChange={(e) => handleForm("birth_year", e.target.value)}
                    defaultValue={profile.birth_year}
                    placeholder="Select"
                    class="block appearance-none text-xs w-full bg-white border border-gray-200 text-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    {getDropList()}
                  </select>
                </div>
              </div>

              <div class="w-1/3 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  City
                </label>
                <input
                  defaultValue={profile.city}
                  onChange={(e) => handleForm("city", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Albuquerque"
                />
              </div>

              <div class="w-1/3 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Country
                </label>
                <div class="relative">
                  {countries.length ? (
                    <select
                      onChange={(e) =>
                        handleForm("temp_country", e.target.value)
                      }
                      defaultValue={profile.temp_country}
                      class="block appearance-none text-xs w-full bg-white border border-gray-200 text-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {countries?.map((country) => (
                        <option value={country.iso2}>{country.name}</option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Country Code
                </label>
                <div class="relative">
                  {countryCodes.length ? (
                    <select
                      onChange={(e) =>
                        handleForm("dial_prefix", e.target.value)
                      }
                      defaultValue={profile.dial_prefix}
                      class="block appearance-none text-xs w-full bg-white border border-gray-200 text-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value={""}>Select country code</option>
                      {countryCodes?.map((country) => (
                        <option value={country.Dial}>
                          {country.Dial} ({country.official_name_en})
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Phone number
                </label>
                <input
                  onChange={(e) => handleForm("phone_number", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  defaultValue={profile.phone_number}
                  placeholder="#123 456 789"
                />
              </div>
            </div>
            <div class="w-full mb-6 ">
              <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Facebook ID
              </label>
              <input
                class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                onChange={(e) => handleForm("facebook_ID", e.target.value)}
                defaultValue={profile.facebook_ID}
                placeholder="Handle ID"
              />
            </div>
            <div class="w-full mb-6 ">
              <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Twitter ID
              </label>
              <input
                class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                onChange={(e) => handleForm("twitter_ID", e.target.value)}
                defaultValue={profile.twitter_ID}
                placeholder="Handle ID"
              />
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Instagram ID
                </label>
                <input
                  defaultValue={profile.instagram_ID}
                  onChange={(e) => handleForm("instagram_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Handle ID"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Twitch ID
                </label>
                <input
                  defaultValue={profile.twitch_ID}
                  onChange={(e) => handleForm("twitch_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Handle ID"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Threads ID
                </label>
                <input
                  onChange={(e) => handleForm("threads_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={profile.threads_ID}
                  placeholder="@Handle"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Snapchat ID
                </label>
                <input
                  onChange={(e) => handleForm("snapchat_ID", e.target.value)}
                  defaultValue={profile.snapchat_ID}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="HANDLE (No @)"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Discord ID
                </label>
                <input
                  onChange={(e) => handleForm("discord_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={profile.discord_ID}
                  placeholder="HANDLE (No @)"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Mastodon ID
                </label>
                <input
                  onChange={(e) => handleForm("mastodon_ID", e.target.value)}
                  defaultValue={profile.mastodon_ID}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="@handle@SERVER"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Bluesky ID
                </label>
                <input
                  onChange={(e) => handleForm("bluesky_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={profile.bluesky_ID}
                  placeholder="Handle ID"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Signal ID
                </label>
                <input
                  onChange={(e) => handleForm("signal_ID", e.target.value)}
                  defaultValue={profile.signal_ID}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="User Name"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Whatsapp ID
                </label>
                <input
                  onChange={(e) => handleForm("whatsapp_ID", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={profile.whatsapp_ID}
                  placeholder="Number"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Telegram ID
                </label>
                <input
                  onChange={(e) => handleForm("telegram_ID", e.target.value)}
                  defaultValue={profile.telegram_ID}
                  class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Handle (No @)"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Joined on
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    profile.created_at
                      ? moment(profile.created_at).format("DD MMMM YYYY")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                  Source platform
                </label>
                <input
                  defaultValue={profile.fan_source_platform}
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Spotify"
                />
              </div>
            </div>

            <div class="w-full mb-6 ">
              <label class="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Last updated at
              </label>
              <input
                class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                disabled={true}
                defaultValue={
                  profile.updated_at
                    ? moment(profile.updated_at).format("LLL")
                    : ""
                }
                placeholder="Date"
              />
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
}

export default EditProfile;
