import React, { useEffect, useState } from "react";
import {
  updateFanCustomNewsfeed,
  updateProfileData,
  getArtistsByRtsvrfId,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { toast } from "react-toastify";
import {
  DataSharingPreference,
  HomePagePreference,
  customArtistActivities,
  customArtistSources,
  customMessageChannels,
  modalCustomStyles,
} from "../rtsvrf-utils/Constants";
import SelectArtist from "./SelectArtist";
import Info from "../rtsvrf-assets/icons/info.png";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { isArrayEqual } from "../rtsvrf-utils/Helpers";
import suprsend, { PreferenceOptions } from "@suprsend/web-sdk";

Modal.setAppElement("#root");
function Settings({
  user,
  userIpData,
  reloadData,
  followedArtists,
  customNewsFeed,
}) {
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState(user);
  const [newsFeed, setNewsFeed] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedArtists, setSelectedArtists] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setProfile(user);
      const response1 = await suprsend.user.preferences.get_preferences();
      console.log(response1, "initial preference");
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    const getNewsFeed = async () => {
      if (customNewsFeed) setNewsFeed(customNewsFeed);
      if (customNewsFeed?.custom_artist_list) {
        const list = await getArtistsByRtsvrfId(
          customNewsFeed?.custom_artist_list
        );
        if (list) setSelectedArtists(list);
      }
    };
    getNewsFeed();
  }, [customNewsFeed]);

  const handleForm = (name, value) => {
    if (name === "custom_channel_list") {
      let list = profile[name] || [];
      if (list.includes(value)) {
        list = list.filter((e) => e !== value);
      } else {
        list = [...list, value];
      }
      setProfile({
        ...profile,
        [name]: list,
      });
    } else {
      setProfile({ ...profile, [name]: value });
    }
  };

  const handlePreferences = async (selectedChannels) => {
    const channels = Object.keys(customMessageChannels).filter(
      (e) => e !== customMessageChannels.push
    );
    channels.forEach((channel) => {
      if (selectedChannels.includes(channel)) {
        console.log("opt_in", channel);
        const response =
          suprsend.user.preferences.update_channel_preference_in_category(
            channel,
            PreferenceOptions.OPT_IN,
            process.env.REACT_APP_DEFAULT_NOTIFICATION_CATEGORY
          );
        if (response.error) {
          console.log(response.message);
        } else {
          console.log(response);
        }
      } else {
        console.log("opt_out", channel);
        const response =
          suprsend.user.preferences.update_channel_preference_in_category(
            channel,
            PreferenceOptions.OPT_OUT,
            process.env.REACT_APP_DEFAULT_NOTIFICATION_CATEGORY
          );
        if (response.error) {
          console.log(response.message);
        } else {
          console.log(response);
        }
      }
    });
  };

  const handleNewsFeed = (name, value) => {
    if (name === "custom_activity_list" || name === "custom_source_list") {
      let list = newsFeed[name] || [];
      if (list.includes(value)) {
        list = list.filter((e) => e !== value);
      } else {
        list = [...list, value];
      }
      setNewsFeed({
        ...newsFeed,
        [name]: list,
        [name === "custom_source_list"
          ? "custom_artist_source"
          : "custom_artist_activities"]: true,
      });
    } else {
      setNewsFeed({ ...newsFeed, [name]: value });
    }
  };

  const selectFavouriteArtist = (selectedOption, name) => {
    console.log(selectedOption, "option");
    if (
      followedArtists?.find(
        (artist) => artist.rtsvrf_artist_id === selectedOption.rtsvrf_artist_id
      )
    ) {
      toast.error("Select unique artist!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setSelectedArtists(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(profile, newsFeed);
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save profile",
      action: "click",
      label: "Save profile",
    });
    handlePreferences(profile.custom_channel_list);
    const settingsDifference = [
      "data_sharing_preference",
      "homepage_preference",
      "messaging_preference",
      "artists_sync_frequency",
    ].filter((k) => profile[k] !== user[k]);
    const settingsDetails =
      settingsDifference?.map(
        (field) => `${field} changed from ${user[field]} to ${profile[field]}`
      ) || [];

    const newsFeedDifference = Object.keys(newsFeed).filter(
      (k) => newsFeed[k] !== customNewsFeed[k]
    );
    const customArtistList =
      selectedArtists.map((artist) => artist?.rtsvrf_artist_id) || [];

    console.log(newsFeedDifference);
    const newsFeedDetails =
      newsFeedDifference?.map(
        (field) =>
          `${field} changed from ${customNewsFeed[field]} to ${newsFeed[field]}`
      ) || [];
    await logSuccess(`Settings updated - ${user?.fan_name}`, {
      ...userIpData,
      email: user?.fan_email,
      timestamp: new Date(),
      type: "Fan",
      details: `${[...newsFeedDetails, ...settingsDetails].join(",")}, ${
        !isArrayEqual(customNewsFeed.custom_artist_list, customArtistList)
          ? `custom_artist_list changed from ${customNewsFeed.custom_artist_list} to ${customArtistList}`
          : ""
      }, ${
        !isArrayEqual(user?.custom_channel_list, profile?.custom_channel_list)
          ? `custom_channel_list changed from ${user.custom_channel_list} to ${profile.custom_channel_list}`
          : ""
      }`,
    });

    const result = await updateProfileData(user.rtsvrf_fan_uuid, profile);
    const newsResult = await updateFanCustomNewsfeed({
      ...newsFeed,
      custom_artist_list: customArtistList,
    });
    setLoading(false);
    if (!result.error && !newsResult.error) {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      reloadData();
    } else {
      toast.error("Something went wrong! Try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {profile ? (
        <div className="my-10 sm:w-full min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My Settings
          </h3>
          <form className="w-full flex items-baseline md:flex-col justify-around bg-gray-200 shadow-xl rounded-lg p-4">
            <div className="pr-4 w-full">
              <h3 className="text-gray-800 text-sm font-bold mb-2">Homepage</h3>
              <p className="text-xs text-gray-500 font-bold my-1 w-full">
                I want my homepage to show:
              </p>
              <div className="w-full mb-6 flex flex-col">
                <div class="mb-1 flex items-center">
                  <input
                    id={HomePagePreference.FollowedArtists}
                    type="radio"
                    checked={
                      profile.homepage_preference ===
                      HomePagePreference.FollowedArtists
                    }
                    onClick={() =>
                      handleForm(
                        "homepage_preference",
                        HomePagePreference.FollowedArtists
                      )
                    }
                    value={HomePagePreference.FollowedArtists}
                    name="homepage-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={HomePagePreference.FollowedArtists}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    All Followed Artists (default)
                  </label>
                </div>
                <div class="mb-1 flex items-center">
                  <input
                    id={HomePagePreference.CustomNewsFeed}
                    type="radio"
                    checked={
                      profile.homepage_preference ===
                      HomePagePreference.CustomNewsFeed
                    }
                    onClick={() =>
                      handleForm(
                        "homepage_preference",
                        HomePagePreference.CustomNewsFeed
                      )
                    }
                    value={HomePagePreference.CustomNewsFeed}
                    name="homepage-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={HomePagePreference.CustomNewsFeed}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    My Custom News Feed
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={HomePagePreference.AVInbox}
                    type="radio"
                    checked={
                      profile.homepage_preference === HomePagePreference.AVInbox
                    }
                    onClick={() =>
                      handleForm(
                        "homepage_preference",
                        HomePagePreference.AVInbox
                      )
                    }
                    value={HomePagePreference.AVInbox}
                    name="homepage-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={HomePagePreference.AVInbox}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    My AV Inbox
                  </label>
                </div>
              </div>

              <h3 className="text-gray-800 text-sm font-bold mb-2">
                Feed Filter
              </h3>
              <p className="text-xs text-gray-500 font-bold my-1 w-full">
                I want my artist news to include:
              </p>
              <div className="w-full mb-6 flex flex-col">
                <div class="mb-1 flex items-center">
                  <input
                    id={"custom_artist_selection-1"}
                    type="radio"
                    checked={newsFeed?.custom_artist_selection === false}
                    onClick={() =>
                      handleNewsFeed("custom_artist_selection", false)
                    }
                    value={false}
                    name="custom_artist_selection"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_selection-1"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    All my artists (all followed)
                  </label>
                </div>
                <div class="mb-1 flex items-center">
                  <input
                    id={"custom_artist_selection-2"}
                    type="radio"
                    checked={newsFeed?.custom_artist_selection === true}
                    onClick={() =>
                      handleNewsFeed("custom_artist_selection", true)
                    }
                    value={true}
                    name="custom_artist_selection"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_selection-2"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    Select followed artists
                  </label>
                </div>
                <div class="my-2 flex items-center">
                  <SelectArtist
                    artistData={followedArtists}
                    name={"favourite_1"}
                    isMulti={true}
                    internalSearchOnly={true}
                    selectedArtist={selectedArtists || []}
                    handleArtistSelection={selectFavouriteArtist}
                  />
                </div>

                <div class="mb-1 mt-2 flex items-center">
                  <input
                    id={"custom_artist_activities-1"}
                    type="radio"
                    checked={newsFeed?.custom_artist_activities === false}
                    onClick={() =>
                      handleNewsFeed("custom_artist_activities", false)
                    }
                    value={false}
                    name="custom_artist_activities"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_activities-1"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    Everything they do
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={"custom_artist_activities-2"}
                    type="radio"
                    checked={newsFeed?.custom_artist_activities === true}
                    onClick={() =>
                      handleNewsFeed("custom_artist_activities", true)
                    }
                    value={true}
                    name="custom_artist_activities"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_activities-2"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    Select specific activities
                  </label>
                </div>

                <div class="mb-1 mt-1 ml-6 flex items-center">
                  <input
                    id={customArtistActivities.NewMusic}
                    type="checkbox"
                    value={customArtistActivities.NewMusic}
                    name="custom_activity_list"
                    checked={newsFeed?.custom_activity_list?.includes(
                      customArtistActivities.NewMusic
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_activity_list",
                        customArtistActivities.NewMusic
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistActivities.NewMusic}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    New music
                  </label>
                </div>
                <div class="mb-1 ml-6 flex items-center">
                  <input
                    id={customArtistActivities.ToursAndTickets}
                    type="checkbox"
                    value={customArtistActivities.ToursAndTickets}
                    name="custom_activity_list"
                    checked={newsFeed?.custom_activity_list?.includes(
                      customArtistActivities.ToursAndTickets
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_activity_list",
                        customArtistActivities.ToursAndTickets
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistActivities.ToursAndTickets}
                    class="ml-2 text-xs  font-normal text-black"
                  >
                    Tours and tickets
                  </label>
                </div>

                <div class="mb-1 ml-6 flex items-center">
                  <input
                    id={customArtistActivities.Merch}
                    type="checkbox"
                    value={customArtistActivities.Merch}
                    name="custom_activity_list"
                    checked={newsFeed?.custom_activity_list?.includes(
                      customArtistActivities.Merch
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_activity_list",
                        customArtistActivities.Merch
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistActivities.Merch}
                    class="ml-2 text-xs  font-normal text-black"
                  >
                    Merch
                  </label>
                </div>

                <div class="mb-1 ml-6 flex items-center">
                  <input
                    id={customArtistActivities.SpecialOffers}
                    type="checkbox"
                    value={customArtistActivities.SpecialOffers}
                    name="custom_activity_list"
                    checked={newsFeed?.custom_activity_list?.includes(
                      customArtistActivities.SpecialOffers
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_activity_list",
                        customArtistActivities.SpecialOffers
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistActivities.SpecialOffers}
                    class="ml-2 text-xs  font-normal text-black"
                  >
                    Special offers
                  </label>
                </div>

                <div class="mb-1 mt-2 flex items-center">
                  <input
                    id={"custom_artist_source-1"}
                    type="radio"
                    checked={newsFeed?.custom_artist_source === false}
                    onClick={() =>
                      handleNewsFeed("custom_artist_source", false)
                    }
                    value={false}
                    name="custom_artist_source"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_source-1"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    Everywhere it appears
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={"custom_artist_source-2"}
                    type="radio"
                    checked={newsFeed?.custom_artist_source === true}
                    onClick={() => handleNewsFeed("custom_artist_source", true)}
                    value={true}
                    name="custom_artist_source"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"custom_artist_source-2"}
                    class="ml-2 text-xs font-bold text-gray-500"
                  >
                    Select specific sources
                  </label>
                </div>
                <div class="mb-1 mt-1 ml-6 flex items-center">
                  <input
                    id={customArtistSources.WebNews}
                    type="checkbox"
                    value={customArtistSources.WebNews}
                    name="custom_source_list"
                    checked={newsFeed?.custom_source_list?.includes(
                      customArtistSources.WebNews
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_source_list",
                        customArtistSources.WebNews
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistSources.WebNews}
                    class="ml-2 text-xs  font-normal text-black"
                  >
                    Web news (general)
                  </label>
                </div>
                <div class="mb-1 ml-6 flex items-center">
                  <input
                    id={customArtistSources.SocialMedia}
                    type="checkbox"
                    value={customArtistSources.SocialMedia}
                    name="custom_source_list"
                    checked={newsFeed?.custom_source_list?.includes(
                      customArtistSources.SocialMedia
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_source_list",
                        customArtistSources.SocialMedia
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistSources.SocialMedia}
                    class="ml-2 text-xs  font-normal text-black"
                  >
                    Social media (Twitter, Facebook, Instagram)
                  </label>
                </div>

                <div class="mb-1 ml-6 flex items-center">
                  <input
                    id={customArtistSources.ArtistDirectOnly}
                    type="checkbox"
                    value={customArtistSources.ArtistDirectOnly}
                    name="custom_source_list"
                    checked={newsFeed?.custom_source_list?.includes(
                      customArtistSources.ArtistDirectOnly
                    )}
                    onClick={() =>
                      handleNewsFeed(
                        "custom_source_list",
                        customArtistSources.ArtistDirectOnly
                      )
                    }
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={customArtistSources.ArtistDirectOnly}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Artist direct only (from source)
                  </label>
                </div>
              </div>
            </div>
            <div className="pr-4 w-full">
              <h3 className="text-gray-800 text-sm font-bold mb-2">
                Data Sharing
              </h3>
              <p
                onClick={() => setOpen(true)}
                className="text-xs flex underline cursor-pointer items-center text-gray-500 font-bold my-1 w-full"
              >
                I want to share my data:
                <span className="ml-auto">
                  <img src={Info} alt="info" width={"20"} height={"20"} />
                </span>
              </p>
              <div className="w-full mb-6 flex flex-col">
                <div class="mb-1 flex items-center">
                  <input
                    id={DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED}
                    type="radio"
                    value={DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED}
                    name="data-sharing"
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={
                      profile.data_sharing_preference ===
                      DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED
                    }
                    onClick={() =>
                      handleForm(
                        "data_sharing_preference",
                        DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED
                      )
                    }
                  />
                  <label
                    for={DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Share all my data with all artists I follow
                  </label>
                </div>
                <div class="mb-1 flex items-center">
                  <input
                    id={DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED}
                    type="radio"
                    value={DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED}
                    name="data-sharing"
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={
                      profile.data_sharing_preference ===
                      DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED
                    }
                    onClick={() =>
                      handleForm(
                        "data_sharing_preference",
                        DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED
                      )
                    }
                  />
                  <label
                    for={DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Share only my communications data with all artists I follow
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED}
                    type="radio"
                    value={DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED}
                    name="data-sharing"
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={
                      profile.data_sharing_preference ===
                      DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED
                    }
                    onClick={() =>
                      handleForm(
                        "data_sharing_preference",
                        DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED
                      )
                    }
                  />
                  <label
                    for={DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Share only my demographic data with all artists I follow
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED}
                    type="radio"
                    value={DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED}
                    name="data-sharing"
                    class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={
                      profile.data_sharing_preference ===
                      DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED
                    }
                    onClick={() =>
                      handleForm(
                        "data_sharing_preference",
                        DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED
                      )
                    }
                  />
                  <label
                    for={DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Don't share any of my data with artists I follow
                  </label>
                </div>
              </div>
              <h3 className="text-gray-800 text-sm font-bold mb-2">
                Messaging
              </h3>
              {/* <p className="text-xs text-gray-500 font-bold my-1 w-full">
                AV Inbox Status
              </p>
              <div className="w-full mb-6 flex flex-col">
                <div class="mb-1 flex items-center">
                  <input
                    id={"OPTED_IN"}
                    type="radio"
                    checked={profile.messaging_preference === "OPTED_IN"}
                    onClick={() =>
                      handleForm("messaging_preference", "OPTED_IN")
                    }
                    value={"OPTED_IN"}
                    name="messaging-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"OPTED_IN"}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Active
                  </label>
                </div>

                <div class="mb-1 flex items-center">
                  <input
                    id={"OPTED_OUT"}
                    type="radio"
                    checked={profile.messaging_preference === "OPTED_OUT"}
                    onClick={() =>
                      handleForm("messaging_preference", "OPTED_OUT")
                    }
                    value={"OPTED_OUT"}
                    name="messaging-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"OPTED_OUT"}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Inactive
                  </label>
                </div>
              </div> */}
              <p className="text-xs text-gray-500 font-bold my-1 w-full">
                Custom Message Channels
              </p>

              <div class="mb-1 mt-1 flex items-center">
                <input
                  disabled
                  id={customMessageChannels.push}
                  type="checkbox"
                  value={customMessageChannels.push}
                  name="custom_channel_list"
                  checked={profile?.custom_channel_list?.includes(
                    customMessageChannels.push
                  )}
                  onClick={() =>
                    handleForm(
                      "custom_channel_list",
                      customMessageChannels.push
                    )
                  }
                  class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for={customMessageChannels.push}
                  class="ml-2 text-xs font-normal text-black"
                >
                  In-App
                </label>
              </div>
              <div class="mb-1 mt-1 flex items-center">
                <input
                  id={customMessageChannels.email}
                  type="checkbox"
                  value={customMessageChannels.email}
                  name="custom_channel_list"
                  checked={profile?.custom_channel_list?.includes(
                    customMessageChannels.email
                  )}
                  onClick={() =>
                    handleForm(
                      "custom_channel_list",
                      customMessageChannels.email
                    )
                  }
                  class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for={customMessageChannels.email}
                  class="ml-2 text-xs font-normal text-black"
                >
                  Email
                </label>
              </div>
              <div class="mb-1 mt-1 flex items-center">
                <input
                  id={customMessageChannels.sms}
                  type="checkbox"
                  value={customMessageChannels.sms}
                  name="custom_channel_list"
                  checked={profile?.custom_channel_list?.includes(
                    customMessageChannels.sms
                  )}
                  onClick={() =>
                    handleForm("custom_channel_list", customMessageChannels.sms)
                  }
                  class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for={customMessageChannels.sms}
                  class="ml-2 text-xs font-normal text-black"
                >
                  Sms/Text
                </label>
              </div>
              <div class="mb-1 mt-1 flex items-center">
                <input
                  id={customMessageChannels.web3}
                  type="checkbox"
                  value={customMessageChannels.web3}
                  name="custom_channel_list"
                  checked={profile?.custom_channel_list?.includes(
                    customMessageChannels.web3
                  )}
                  onClick={() =>
                    handleForm(
                      "custom_channel_list",
                      customMessageChannels.web3
                    )
                  }
                  class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for={customMessageChannels.web3}
                  class="ml-2 text-xs font-normal text-black"
                >
                  Secure Web3 Messaging
                </label>
              </div>

              <h3 className="text-gray-800 text-sm font-bold mt-4">
                Keep artist interests up to date
              </h3>
              <p className="text-xs text-gray-500 font-bold my-1 w-full">
                Update frequency:
              </p>
              <div className="w-full mb-6 flex flex-col">
                <div class="mb-1 flex items-center">
                  <input
                    id={"Weekly"}
                    type="radio"
                    checked={profile.artists_sync_frequency === "Weekly"}
                    onClick={() =>
                      handleForm("artists_sync_frequency", "Weekly")
                    }
                    value={"Weekly"}
                    name="prompt-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"Weekly"}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Weekly basis
                  </label>
                </div>
                <div class="mb-1 flex items-center">
                  <input
                    id={"Monthly"}
                    type="radio"
                    checked={profile.artists_sync_frequency === "Monthly"}
                    onClick={() =>
                      handleForm("artists_sync_frequency", "Monthly")
                    }
                    value={"Monthly"}
                    name="prompt-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"Monthly"}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Monthly basis
                  </label>
                </div>
                <div class="mb-1 flex items-center">
                  <input
                    id={"Never"}
                    type="radio"
                    checked={profile.artists_sync_frequency === "Never"}
                    onClick={() =>
                      handleForm("artists_sync_frequency", "Never")
                    }
                    value={"Never"}
                    name="prompt-preference"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for={"Never"}
                    class="ml-2 text-xs font-normal text-black"
                  >
                    Never
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className="flex justify-center my-2">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>

          <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            style={modalCustomStyles}
            contentLabel="Settings Modal"
          >
            <div className="flex flex-col p-4 text-black items-center">
              <h2 className="text-black font-semibold text-sm py-3">
                ArtistVerified takes user data privacy and data sharing
                seriously. There are benefits for artists and fans to sharing
                data, but we believe that accessing these benefits should be
                driven by user choice, rather than because a platform provider
                decides to monetize it. In this section:
              </h2>

              <ul>
                <li className="list-disc ml-2 font-normal text-xs">
                  "Share all my data with all artists I follow" means artists
                  you follow will be able to access your name, email, phone
                  number, city, country, Twitter handle, Facebook handle,
                  Threads handle, Snapchat handle, Mastodon handle, Bluesky
                  handle, Discord handle, Instagram handle, Signal handle,
                  Whatsapp handle, Telegram handle, gender, year of birth,
                  favourite artist selections, wallet connection, fan status
                  level.
                </li>
                <li className="list-disc ml-2 font-normal text-xs">
                  "Share only my communications data with all artists I follow"
                  means artists you follow will be able to access your name,
                  email, phone number, Twitter handle, Facebook handle, Threads
                  handle, Snapchat handle, Mastodon handle, Bluesky handle,
                  Discord handle, Instagram handle, Signal handle, Whatsapp
                  handle, Telegram handle only. No other data recorded as a part
                  of your fan profile will be accessible to artists you follow.
                </li>
                <li className="list-disc ml-2 font-normal text-xs">
                  "Share only my demographic data with all artists I follow"
                  means artists you follow will be able to access your city,
                  country, gender, year of birth. No other data recorded as a
                  part of your fan profile will be accessible to artists you
                  follow.
                </li>
                <li className="list-disc ml-2 font-normal text-xs">
                  Don't share any of my data with artists I follow" means
                  artists you follow cannot see any of your data on the
                  ArtistVerified platform. Your artist following data will still
                  be included in overall statistics.
                </li>
              </ul>
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  );
}

export default Settings;
