import { supabase } from "../rtsvrf-database/SupabaseClient";
import {
  SPOTIFY_LINK,
  SPOTIFY_UNKNOWN,
  userNotificationMapping,
} from "../rtsvrf-utils/Constants";
import { getTimestampInSeconds, shuffleArray } from "../rtsvrf-utils/Helpers";
import { logSuccess } from "./Logging";

export const getFanProfile = async (email) => {
  if (!email) return;
  const results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select()
    .eq("fan_email", email)
    .single();
  return results.data;
};

export const getFanSubscription = async (id) => {
  if (!id) return;
  const results = await supabase
    .from("tbl_staged_fan_subsdata")
    .select()
    .eq("rtsvrf_fan_uuid", id)
    .single();
  return results.data ?? null;
};

export const getFanProfileById = async (id) => {
  if (!id) return;
  const results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select()
    .eq("rtsvrf_fan_uuid", id)
    .single();
  return results.data;
};

export const getUserData = async (profile, userIpData) => {
  if (!profile || !profile.fan_email) return;
  let user = await getFanProfile(profile.fan_email);

  if (!user) {
    await insertProfileData(profile);
    user = await getFanProfile(profile.fan_email);
    if (user) {
      await logSuccess("Sign Up", {
        ...userIpData,
        email: profile.fan_email,
        timestamp: new Date(),
        type: "Fan",
        details: profile.fan_source_platform,
      });
      if (!user.suprsend_subscriber_id) {
        const suprsend_subscriber_id = await getUserSubscriberKey(
          user.rtsvrf_fan_uuid
        );
        user = { ...user, suprsend_subscriber_id };
      }
      await notifyUser(user.rtsvrf_fan_uuid, {
        details: { UserName: user.fan_name },
        type: userNotificationMapping.welcome,
        name: `${userNotificationMapping.welcome}${
          user.fan_name || user.fan_email
        }`,
      });
    }
    return user;
  } else {
    if (!user.suprsend_subscriber_id) {
      const suprsend_subscriber_id = await getUserSubscriberKey(
        user.rtsvrf_fan_uuid
      );
      user = { ...user, suprsend_subscriber_id };
    }
    return user;
  }
};

export const getUserSubscriberKey = async (userId) => {
  if (!userId) return;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/subscriber-key/${userId}`,
    options
  );
  const result = await response.json();
  if (result?.subscriberKey) {
    await updateProfileData(userId, {
      suprsend_subscriber_id: result?.subscriberKey,
    });
  }
  return result?.subscriberKey ?? null;
};

export const getUserAppleJwtToken = async () => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/get-jwt-token`,
    options
  );
  const result = await response.json();
  return result;
};

export const insertProfileData = async (data) => {
  if (!data) return;
  let results = await supabase.from("tbl_staged_fan_user_profile").insert(data);
  return results;
};
export const syncAndUpdateSpotifyArtistData = async (
  artists,
  userId,
  userIpData,
  platformOrigin
) => {
  if (!artists || !artists.length) return;
  const artistIds = artists?.map((artist) => artist.id) ?? [];

  const id = "artist_spotfy_id";
  const savedArtists = await getFollowedArtistsBySpecificId(
    artistIds,
    id,
    artists
  );
  if (savedArtists?.length !== artistIds.length && artists) {
    const missingArtistIds = [];
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name;
      if (
        !savedArtists?.find(
          (e) => e[id] === artist.id || e?.artist_name === artistName
        )
      ) {
        missingArtistIds.push(artist.id);
        const insertArtist = await insertArtistBasicProfile({
          artist_spotfy_id: artist.id,
          artist_spotfy_link: `${SPOTIFY_LINK}${artist.id}`,
          artist_name: artistName,
          artist_origin_fan: userId,
          artist_origin_platform: platformOrigin,
        });
        await logSuccess("Inserted new artist", {
          ...userIpData,
          artistId: artist.id,
          timestamp: new Date(),
          type: "Fan",
          details: `New artist - ${artistName} added from ${platformOrigin}}`,
        });
      }
    }
    const followedArtists = await getFollowedArtistsBySpecificId(
      missingArtistIds,
      id
    );

    return followedArtists.concat(savedArtists);
  } else return savedArtists;
};

export const syncAndUpdateAppleArtistData = async (
  artists,
  userId,
  userIpData,
  originPlatform
) => {
  if (!artists || !artists.length) return;
  const artistIds = artists?.map((artist) => artist.id) ?? [];
  const id = "artist_aplmus_id";
  const savedArtists = await getFollowedArtistsBySpecificId(
    artistIds,
    id,
    artists
  );

  if (savedArtists?.length !== artistIds.length && artists) {
    const missingArtistIds = [];
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name || artist?.attributes?.name;
      if (
        !savedArtists?.find(
          (e) => e[id] === artist.id || e?.artist_name === artistName
        )
      ) {
        missingArtistIds.push(artist.id);
        const insertArtist = await insertArtistBasicProfile({
          artist_spotfy_id: `${SPOTIFY_UNKNOWN}_${getTimestampInSeconds()}`,
          artist_aplmus_id: artist.id,
          artist_name: artistName,
          artist_genre: artist?.attributes?.genreNames
            ? artist.attributes.genreNames.join(",")
            : null,
          artist_origin_fan: userId,
          artist_origin_platform: originPlatform,
        });
        await logSuccess("Inserted new artist", {
          ...userIpData,
          artistId: artist.id,
          timestamp: new Date(),
          type: "Fan",
          details: `New artist - ${artistName} added from APPLE`,
        });
      }
    }
    const followedArtists = await getFollowedArtistsBySpecificId(
      missingArtistIds,
      id
    );

    return followedArtists.concat(savedArtists);
  } else return savedArtists;
};

export const getFollowedArtistsBySpecificId = async (
  artistIds,
  id,
  artists
) => {
  if (!artistIds || !id) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .in(id, artistIds)
    .order("artist_name", { ascending: true });
  const presentArtists = results.data;
  if (presentArtists?.length !== artists?.length && artists) {
    for (let index = 0; index < artists?.length; index++) {
      const artist = artists[index];
      const artistName = artist?.name || artist?.attributes?.name;
      if (!presentArtists.find((e) => e[id] === artist.id) && artistName) {
        try {
          const artistData = await getFollowedArtistByName(artistName);
          if (artistData?.length) presentArtists.push(artistData[0]);
        } catch (error) {
          console.log(error, `${artistName} - Not found by name`);
        }
      }
    }
  }
  return presentArtists;
};

export const getFollowedArtistByName = async (artistName) => {
  if (!artistName) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .eq("artist_name", artistName);
  return results.data;
};

export const getFollowedArtists = async (spotifyIds, appleIds) => {
  if (!spotifyIds && !appleIds) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(
        ","
      )}), artist_aplmus_id.in.(${appleIds.join(",")})`
    )
    .order("artist_name", { ascending: true });
  return results.data;
};

export const getDefaultArtistData = async (limit, slice) => {
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .limit(limit);
  let list = results.data;
  if (list) {
    if (!slice) return list;
    list = shuffleArray(list);
    list = list.slice(0, slice);
    return list;
  } else return [];
};

export const getArtistImage = async (artists) => {
  const results = await supabase
    .from("tbl_locked_artist_pics")
    .select()
    .in("rtsvrf_artist_id", artists);
  return results.data;
};

export const getFullCountryName = async (code) => {
  if (!code) return;
  const results = await supabase
    .from("tbl_locked_countries")
    .select()
    .eq("iso2", code)
    .single();
  return results.data?.name;
};

export const getCountryNames = async () => {
  const results = await supabase.from("tbl_locked_countries").select();
  return results?.data;
};

export const getCountryPhoneNumberCodes = async () => {
  const results = await supabase.from("tbl_locked_phone_codes").select();
  return results?.data;
};

export const insertArtistBasicProfile = async (data) => {
  if (!data) return;
  let results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .insert(data);
  return results;
};

export const updateProfileData = async (id, data) => {
  if (!data || !id) return;
  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .update({
      ...data,
      updated_at: new Date(),
    })
    .eq("rtsvrf_fan_uuid", id);

  return results;
};

export const getArtistsByNameSearch = async (searchString) => {
  if (!searchString) return;

  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .ilike("artist_name", `%${searchString}%`);
  return results.data;
};

export const getArtistsByRtsvrfId = async (artistIds) => {
  if (!artistIds) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .in("rtsvrf_artist_id", artistIds);
  return results.data;
};

export const updateFanCustomNewsfeed = async (data) => {
  if (!data) return;
  const results = await supabase.from("tbl_staged_fan_custom_news").upsert({
    ...data,
    updated_at: new Date(),
  });
  return results;
};

export const getFanCustomNewsfeed = async (fanId) => {
  if (!fanId) return;
  const results = await supabase
    .from("tbl_staged_fan_custom_news")
    .select()
    .eq("rtsvrf_fan_uuid", fanId)
    .single();
  return results.data;
};

export const insertLogHistory = async (data) => {
  if (!data) return;
  let results = await supabase
    .from("tbl_locked_fans_logs_history")
    .insert(data);
  return results;
};

export const getLogHistory = async (email, currentPage = 0, limit = 5) => {
  if (!email) return;
  let results = await supabase
    .from("tbl_locked_fans_logs_history")
    .select()
    .order("timestamp", { ascending: false })
    .eq("email", email)
    .range(currentPage * limit, currentPage * limit + (limit - 1));
  return results;
};

export const getTotalLogHistory = async (email) => {
  if (!email) return;
  let results = await supabase
    .from("tbl_locked_fans_logs_history")
    .select("*", { count: "exact" })
    .eq("email", email);

  return results.count;
};

export const getTotalArtists = async (spotifyIds, appleIds, filters) => {
  let query = supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select("*", { count: "exact" })
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(
        ","
      )}), artist_aplmus_id.in.(${appleIds.join(",")})`
    );

  query = createFilterQuery(query, filters);
  let results = await query;
  return results.count;
};

export const createFilterQuery = async (query, filters) => {
  if (filters?.length) {
    filters.forEach((filter) => {
      if (
        filter.operator === "eq" &&
        (filter.value || typeof filter.value === "boolean")
      )
        query.eq(filter.field, filter.value);
      else if (filter.operator === "not")
        query = query.not(filter.field, "is", filter.value);
      else if (filter.operator === "ilike")
        query = query.ilike(filter.field, `%${filter.value}%`);
    });
  }
  return query;
};

export const getArtistsPaginatedList = async (
  currentPage = 0,
  limit = 5,
  spotifyIds,
  appleIds,
  filters
) => {
  let query = supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .range(currentPage * limit, currentPage * limit + (limit - 1))
    .or(
      `artist_spotfy_id.in.(${spotifyIds.join(
        ","
      )}), artist_aplmus_id.in.(${appleIds.join(",")})`
    )
    .order("artist_name", { ascending: true });
  query = createFilterQuery(query, filters);
  let results = await query;
  return results.data;
};

export const createCourierProfile = async (userId, data = {}) => {
  if (!userId) return;
  const getOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_COURIER_API_KEY}`,
    },
  };

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_COURIER_API_KEY}`,
    },
    body: JSON.stringify({
      profile: data,
    }),
  };

  fetch(
    `${process.env.REACT_APP_PROXY_SERVER}https://api.courier.com/profiles/${userId}`,
    getOptions
  )
    .then((response) => response.json())
    .then((response) => {
      if (!response?.profile?.email) {
        fetch(
          `${process.env.REACT_APP_PROXY_SERVER}https://api.courier.com/profiles/${userId}`,
          options
        )
          .then((response) => response.json())
          .then((response) => console.log(response))
          .catch((err) => console.error(err));
      }
    })
    .catch((err) => console.error(err));
};

export const subscribeUserToMultipleArtists = async (userId, artistsList) => {
  if (!userId || !artistsList || !Array.isArray(artistsList)) return;
  try {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        artistsList,
      }),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/subscribe/artists`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error("Error subscribing user to multiple artists:", error);
  }
};

export const subscribeUserToFavouriteArtist = async (
  userId,
  newFavArtistId,
  artistData,
  oldFavArtistId
) => {
  if (!userId || !newFavArtistId) return;

  try {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          ...artistData,
        },
        unsubscribeArtistId: oldFavArtistId,
      }),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/subscribe/artist/${newFavArtistId}`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error("Error subscribing user to Favourite artist:", error);
  }
};

export const subscribeUserToArtist = async (userId, artistId, artistData) => {
  if (!userId || !artistId) return;

  try {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          ...artistData,
        },
      }),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/subscribe/artist/${artistId}/single`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error("Error subscribing user to an artist:", error);
  }
};

export const unsubscribeUserFromArtist = async (
  userId,

  artistId
) => {
  if (!userId || !artistId) return;

  try {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/unsubscribe/artist/${artistId}/single`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error("Error unsubscribing user from an artist:", error);
  }
};

export const uploadImageToBucket = async (selectedImage, arrayBuffer) => {
  const time = new Date().getTime();
  const { data, error } = await supabase.storage
    .from("bkt_staged_fan_profile")
    .upload(`public/${selectedImage.name}_${time}`, arrayBuffer, {
      contentType: selectedImage.type,
    });

  if (error) {
    return { error: true, message: error };
  } else {
    const res = supabase.storage
      .from("bkt_staged_fan_profile")
      .getPublicUrl(data.path);
    return (
      res?.data?.publicUrl ?? {
        error: true,
        message: "Failed to upload an image",
      }
    );
  }
};

export const notifyUser = async (userId, data) => {
  if (!data) return;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const result = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/notify`,
    options
  );
  return result;
};
